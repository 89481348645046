import axios from "axios";
import { rule5properties } from "../properties";
import { useQuery } from "react-query";

const fetchDeliveryOptions = async () => {
  const response = await axios.get(
    `${rule5properties.values}?name=SalesPlatformDeliveryOptions`
  );
  if (response.data.values) {
    return response.data.values;
  }
  throw new Error("Error loading SalesPlatformDeliveryOptions");
};

export const useDeliveryOptions = () => {
  return useQuery("deliveryOptions", fetchDeliveryOptions, {
    retry: 3,
    retryDelay: 1000,
    onError: (error) =>
      console.log("Error loading SalesPlatformDeliveryOptions:", error),
    placeholderData: [],
  });
};
