import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  IconButton,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import {
  GrayCircularProgress,
  StyledTextfield,
} from "../../../common/StyledComponents";
import DeleteIcon from "@mui/icons-material/Delete";
import { Controller, useFormContext } from "react-hook-form";
import { validateEmailString } from "../../../common/Utils";
import { rule5AppUrl } from "../../../properties";
import monitor_service from "./monitor_service";
import { accountsAgentType } from "./AgentTypes";
import { useOutreachConnection } from "../../../api/integrations";
import { useDeliveryOptions } from "../../../api/values";

export default function ConfigureActions(props) {
  const { agentType, setUserMessageChannels } = props;
  const { getValues, setValue } = useFormContext();

  const [loading, setLoading] = React.useState(false);

  // Reload the user message channels to see if slack channels were changed while this modal was open.
  async function reloadUserMessageChannels() {
    setLoading(true);
    const newUserMessageChannels =
      await monitor_service.getUserMessageChannels();
    if (newUserMessageChannels) {
      setUserMessageChannels(newUserMessageChannels);
    }
    // Filter out and remove any existing channels that are no longer available.
    const newChannelIds = newUserMessageChannels.map(
      (existingMsgChannel) => existingMsgChannel.channelId
    );
    const existingValues = getValues(
      "action.agentActionInfo.notifications.slackChannels"
    );
    const adjustedChannels = existingValues.filter((configedChannel) =>
      newChannelIds.includes(configedChannel.channelId)
    );
    setValue(
      "action.agentActionInfo.notifications.slackChannels",
      adjustedChannels
    );
    setLoading(false);
  }

  return (
    <Stack direction="column" gap="20px">
      <SlackActions
        reloadUserMessageChannels={reloadUserMessageChannels}
        {...loading}
        {...props}
      />
      {agentType.type === accountsAgentType.type && (
        <>
          <Divider sx={{ my: 1 }} />
          <AccountIntegrations />
        </>
      )}
    </Stack>
  );
}

function SlackActions(props) {
  const {
    tempEmail,
    setTempEmail,
    userMessageChannels,
    reloadUserMessageChannels,
    loading,
  } = props;
  const { control } = useFormContext();

  return (
    <Stack direction="column" gap="10px">
      <Stack direction="row" alignItems="center" justifyContent="flex-start">
        <Typography variant="settingsHeader">Generate Alerts?</Typography>
        <Controller
          name="action.agentActionInfo.notifications.isNotificationEnabled"
          control={control}
          render={({ field, onChange }) => (
            <Switch
              {...field}
              name="action.agentActionInfo.notifications.isNotificationEnabled"
              checked={field.value}
            />
          )}
        />
      </Stack>
      <Box>
        <Typography variant="settingsCaption">
          Provide a list of email addresses to receive alerts for this agent.
        </Typography>
        <Controller
          name="action.agentActionInfo.notifications.emails"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              name="action.agentActionInfo.notifications.emails"
              onChange={(e, data) => field.onChange(data)}
              options={[]}
              multiple
              freeSolo
              multiline
              renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                  <ValidatedEmailChip
                    key={option}
                    email={option}
                    getTagProps={getTagProps}
                    index={index}
                  />
                ));
              }}
              inputValue={tempEmail}
              onInputChange={(event, value) => {
                setTempEmail(value.trim());
              }}
              renderInput={(params) => (
                <StyledTextfield
                  {...params}
                  {...params.InputProps}
                  label="Email addresses"
                  sx={{ px: "10px", py: "6px" }}
                />
              )}
            />
          )}
        />
      </Box>
      <Box>
        <Typography variant="settingsCaption">
          Select Slack channels to receive alerts for this agent.{" "}
          <Link
            target="_blank"
            href={rule5AppUrl + "/main/settings/integrations"}
          >
            Can't find your Slack channels? Click here to configure your Slack
            channels with rule5.
          </Link>
        </Typography>
        <Controller
          name="action.agentActionInfo.notifications.slackChannels"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              name="action.agentActionInfo.notifications.slackChannels"
              onChange={(e, data) => {
                field.onChange(data);
              }}
              options={userMessageChannels}
              multiple
              multiline
              renderOption={(props, option) => {
                return (
                  <Box {...props}>
                    <Typography variant="body1">
                      #{option.channelName}
                    </Typography>
                  </Box>
                );
              }}
              getOptionLabel={(x) => `#${x.channelName}`}
              isOptionEqualToValue={(option, val) => {
                const equal = option.id === val.id;
                return equal;
              }}
              renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                  <Chip
                    sx={{ pt: 0.25 }}
                    variant="outlined"
                    label={"#" + option.channelName}
                    {...getTagProps({ index })}
                  />
                ));
              }}
              renderInput={(params) => (
                <StyledTextfield
                  {...params}
                  {...params.InputProps}
                  label="Slack channels"
                  sx={{ px: "10px", py: "6px" }}
                  endAdornment={loading && <GrayCircularProgress size={16} />}
                />
              )}
              onOpen={() => {
                reloadUserMessageChannels();
              }}
            />
          )}
        />
      </Box>
    </Stack>
  );
}

function AccountIntegrations(props) {
  const { control, watch } = useFormContext();

  const { data: configuredOutreachConnection } =
    useOutreachConnection();
  const { data: deliveryOptions } = useDeliveryOptions();

  const disabled =
    !configuredOutreachConnection ||
    !watch("action.agentActionInfo.salesPlatforms.isSalesPlatformEnabled");

  return (
    <Stack direction="column" gap="10px">
      <Stack direction="row" alignItems="center" justifyContent="flex-start">
        <Typography variant="settingsHeader">
          Enable delivery through sales execution platform?
        </Typography>
        <Controller
          name="action.agentActionInfo.salesPlatforms.isSalesPlatformEnabled"
          control={control}
          render={({ field }) => (
            <Switch {...field} name="generateOutreach" checked={field.value} />
          )}
        />
      </Stack>
      <Box>
        <Typography variant="settingsCaption">
          Choose platform for delivery integration.{" "}
          <Link
            target="_blank"
            href={rule5AppUrl + "/main/settings/integrations"}
          >
            Click here to configure your platform integration.
          </Link>
        </Typography>
        <PlatformGroup
          disabled={disabled}
          configuredOutreachConnection={configuredOutreachConnection}
        />
      </Box>
      <Box>
        <Typography variant="settingsCaption">
          Choose delivery option.
        </Typography>
        <Controller
          name="action.agentActionInfo.salesPlatforms.deliveryOption"
          control={control}
          defaultValue={""}
          render={({ field }) => (
            <RadioGroup {...field} sx={{ flexGrow: 1 }}>
              {deliveryOptions.map((option, index) => (
                <RadioOption
                  value={option}
                  text={option}
                  disabled={disabled}
                  key={index}
                />
              ))}
            </RadioGroup>
          )}
        />
      </Box>
    </Stack>
  );
}

function PlatformGroup(props) {
  const { disabled, configuredOutreachConnection } = props;
  const { control } = useFormContext();

  return (
    <Controller
      name="action.agentActionInfo.salesPlatforms.platform"
      control={control}
      defaultValue={""}
      render={({ field }) => (
        <RadioGroup {...field} sx={{ flexGrow: 1 }} row>
          <RadioOption
            value={"Outreach"}
            text={"Outreach"}
            disabled={disabled || !configuredOutreachConnection}
          />
          <RadioOption value={"Hubspot"} text={"Hubspot"} disabled={true} />
          <RadioOption value={"Salesloft"} text={"Salesloft"} disabled={true} />
        </RadioGroup>
      )}
    />
  );
}

function RadioOption(props) {
  const { value, text, disabled } = props;
  return (
    <FormControlLabel
      disabled={disabled}
      sx={{ fontSize: "10px" }}
      value={value}
      control={<Radio size="small" />}
      label={
        <Typography sx={{ fontSize: "14px", userSelect: "none" }}>
          {text}
        </Typography>
      }
    />
  );
}


function ValidatedEmailChip(props) {
  const { email, getTagProps, index } = props;
  return (
    <Chip
      sx={{ pt: 0.25 }}
      color={validateEmailString(email) ? "primary" : "error"}
      variant="outlined"
      label={email}
      {...getTagProps({ index })}
    />
  );
}
