import { Box, ButtonBase, Stack, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { GrayCircularProgress } from "./StyledComponents";
import React from "react";

const TileButtonBase = styled(ButtonBase)(({ theme }) => ({
  height: 200,
  width: 300,
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgb(227 239 248)",
  border: "none",
  borderRadius: "12px",
  outline: "none",
  padding: "15px",
  minHeight: "150px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#ddebf7",
  },
  "&:focus": {
    backgroundColor: "#ddebf7",
  },
}));

export default function TileButton(props) {
  const {
    isLoading,
    disabled,
    AddToButton,
    onClick,
  } = props;
  const [displayAddTo, setDisplayAddTo] = React.useState(null);

  let clickHandler = onClick;
  if (AddToButton && disabled) {
    clickHandler = () => setDisplayAddTo(true);
  }

  if (displayAddTo && !disabled) {
    setDisplayAddTo(false);
  }

  if (displayAddTo) {
    return (
      <Stack
        sx={{
          height: 200,
          width: 300,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AddToButton />
      </Stack>
    );
  }

  return (
    <TileButtonBase
      disableRipple
      {...props}
      disabled={isLoading}
      onClick={clickHandler}
    >
      <PrimaryDisplay {...props} />
    </TileButtonBase>
  );
}

function PrimaryDisplay(props) {
  const { image, title, description, isLoading, disabled } = props;

  let label = "";

  if (isLoading) {
    label = "Loading..";
  } else if (disabled) {
    label = "Enable connection";
  } else {
    label = description;
  }

  return (
    <>
      {isLoading ? (
        <GrayCircularProgress />
      ) : (
        <Tooltip title={title}>
          <img
            src={image}
            alt={`${title} logo`}
            style={{
              height: "40px",
              width: "40px",
            }}
          />
        </Tooltip>
      )}
      <Typography fontWeight={300}>{label}</Typography>
    </>
  );
}