import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useUser } from "../context/UserContext";
import axios from "axios";
import { rule5properties } from "../properties";
import {
  AddToSlackButton,
  SlackIntegration,
} from "./integrations/SlackIntegration";
import { useDialog } from "../context/DialogContext";
import TileButton from "../common/TileButton";
import slackLogo from "../res/slack_button.svg";
import outreachLogo from "../res/outreach_logo.webp";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  AddToOutreachButton,
  OutreachIntegration,
} from "./integrations/OutreachIntegration";
import { useOutreachConnection, useSlackConfig } from "../api/integrations";

export default function Integrations(props) {
  const location = useLocation();
  const userContext = useUser();
  const dialog = useDialog();

  const [selectedIntegration, setSelectedIntegration] = React.useState(null);
  const {
    data: configuredSlackChannels,
    isLoading: isLoadingSlack,
    refetch: refetchSlack,
  } = useSlackConfig();

  const { data: configuredOutreachConnection, isLoading: isLoadingOutreach } =
    useOutreachConnection();

  const INTEGRATIONS = [
    {
      isLoading: isLoadingSlack,
      Component: SlackIntegration,
      image: slackLogo,
      title: "Slack",
      description: "Configure channels and messages",
      disabled: !configuredSlackChannels,
      AddToButton: AddToSlackButton,
    },
    {
      isLoading: isLoadingOutreach,
      Component: OutreachIntegration,
      image: outreachLogo,
      title: "Outreach",
      description: "Configure email settings",
      disabled: !configuredOutreachConnection,
      AddToButton: AddToOutreachButton,
    },
  ];

  // integration code is present, perform integration setup
  useEffect(() => {
    let searchParams = new URLSearchParams(location.search);
    if (
      searchParams.get("code") &&
      searchParams.get("state") === userContext.email
    ) {
      // An authorization code is present and email state match, call Slack callback.
      const params = { code: searchParams.get("code") };
      dialog.openModal(
        "",
        () => (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        ),
        { fixed: true },
        "sm"
      );
      axios
        .post(rule5properties.slackCallback, params)
        .then((res) => {
          if (res.status === 200) {
            refetchSlack();
            dialog.closeModal();
          } else {
            dialog.closeModal();
            console.log("Failed to set up integration.");
            console.log(res);
          }
        })
        .catch((err) => {
          dialog.closeModal();
          console.log("Failed to set up integration:");
          console.log(err);
        });
    }
  }, [location.search, userContext.email, refetchSlack, dialog]);

  console.log("activeTile=" + selectedIntegration);
  return (
    <>
      {selectedIntegration ? (
        <Stack
          sx={{
            backgroundColor: "#fafafa",
            borderRadius: "12px",
            minWidth: "600px",
            height: "50vh",
            gap: "10px",
          }}
        >
          <IconButton
            aria-label="delete"
            sx={{ width: "40px" }}
            onClick={() => {
              setSelectedIntegration(null);
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Box
            sx={{
              backgroundColor: "#f2f3f2",
              padding: "20px",
              height: "100%",
              borderRadius: "12px",
            }}
          >
            <Typography
              variant="h5"
              sx={{ width: "100%", mb: 2, fontWeight: 500 }}
            >
              {selectedIntegration.title}
            </Typography>
            <selectedIntegration.Component
              {...selectedIntegration.componentProps}
            />
          </Box>
        </Stack>
      ) : (
        <Stack
          direction="row"
          sx={{
            minWidth: "600px",
            maxWidth: "1400px",
            flexGrow: 1,
            gap: "20px",
          }}
        >
          {INTEGRATIONS.map((integration) => (
            <TileButton
              key={integration.title}
              onClick={() => {
                setSelectedIntegration(integration);
              }}
              isLoading={integration.isLoading}
              image={integration.image}
              title={integration.title}
              description={integration.description}
              disabled={integration.disabled}
              AddToButton={integration.AddToButton}
            ></TileButton>
          ))}
        </Stack>
      )}
    </>
  );
}
