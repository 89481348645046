import React, { useCallback, useEffect, useRef } from "react";
import axios from "axios";
import { rule5properties } from "../../../properties";
import CustomSnackbar from "../../../common/CustomSnackbar";
import makeStyles from "@mui/styles/makeStyles";
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import stackIcon from "../../../res/icons/outline/16px_stack.svg";
import DownloadIcon from "@mui/icons-material/Download";
import Popover from "@mui/material/Popover";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import SingleRteCard from "./cards/SingleRteCard";
import RteArrayCard from "./cards/IndustryTrends";
import KeyPeriods from "./cards/KeyPeriods";
import News from "./cards/News";
import Orgchart from "./cards/Orgchart";
import Overview from "./cards/Overview";
import PeerGroup from "./cards/PeerGroup";
import People from "./cards/People";
import Placeholder from "./cards/Placeholder";
import RiskFactors10K from "./cards/RiskFactors10K";
import Sellercomp from "./cards/Sellercomp";
import SocialMedia from "./cards/SocialMedia";
import Trends from "./cards/Trends";
import CompanyUpdates from "./cards/CompanyUpdates";
import Button from "@mui/material/Button";
import Methodology from "./cards/Methodology";
import RecommendedActions from "./cards/RecommendedActions";
import TechnologyLandscape from "./cards/TechnologyLandscape";
import CompetitionIntelligence from "./cards/CompetitionIntelligence";
import {
  cardTypeToId,
  dataURItoBlob,
  exportAsImage,
} from "../../../common/Utils";
import FormControl from "@mui/material/FormControl";
import Container from "@mui/material/Container";
import Operations from "./cards/Operations";
import ExecutiveSummary from "./cards/ExecutiveSummary";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Card, Collapse, Divider } from "@mui/material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import ShareholderReturn from "./cards/ShareholderReturn";
import FlashCard from "./cards/FlashCard";
import AnnualReports from "./cards/AnnualReports";
import QuarterlyEarnings from "./cards/QuarterlyEarnings";
import SpSiBc from "./cards/SpSiBc";
import Discovery from "./cards/Discovery";
import { useDisplayContext } from "../../../context/DisplayContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

import ForumIcon from "@mui/icons-material/Forum";
import CompanyInsights from "./cards/CompanyInsights";
import Signals from "./cards/Signals";
import AddToAgent from "../../../common/AddToAgent";

export const cardTypes = [
  {
    cardType: "Company Overview",
    dom: React.memo(Overview),
    displayName: "Overview",
  },
  { cardType: "People", dom: React.memo(People), displayName: "People" },
  { cardType: "News", dom: React.memo(News), displayName: "News" },
  {
    cardType: "Competitor News",
    dom: React.memo(News), // Same as news card.
    displayName: "Competitor News",
  },
  {
    cardType: "Shareholder Return",
    dom: React.memo(ShareholderReturn),
    displayName: "Shareholder Return",
  },
  {
    cardType: "Seller Competition",
    dom: React.memo(Sellercomp),
    displayName: "Seller Competition",
  },
  {
    cardType: "Buyer Peer Group",
    dom: React.memo(PeerGroup),
    displayName: "Peer Group",
  },
  {
    cardType: "Org Chart",
    dom: React.memo(Orgchart),
    displayName: "Organization Chart",
  },
  {
    cardType: "Social Media",
    dom: React.memo(SocialMedia),
    displayName: "Social Media",
  },
  {
    cardType: "Key Periods",
    dom: React.memo(KeyPeriods),
    displayName: "Key Periods",
  },
  {
    cardType: "Trends",
    dom: React.memo(Trends),
    displayName: "Trends",
  },
  {
    cardType: "Company Updates",
    dom: React.memo(CompanyUpdates),
    displayName: "Company Updates",
  },
  {
    cardType: "Benchmarks",
    dom: React.memo(Placeholder),
    displayName: "Benchmarks",
  },
  {
    cardType: "Industry Trends",
    dom: React.memo(RteArrayCard),
    displayName: "Industry Trends",
  },
  {
    cardType: "Risk Factors",
    dom: React.memo(RiskFactors10K),
    displayName: "Risk Factors",
  },
  {
    cardType: "Executive Summary",
    dom: React.memo(ExecutiveSummary),
    displayName: "Executive Summary",
  },
  {
    cardType: "Custom",
    dom: React.memo(Placeholder),
    displayName: "Custom",
  },
  {
    cardType: "Strategic Priorities",
    dom: React.memo(RteArrayCard),
    displayName: "Strategic Priorities",
  },
  {
    cardType: "Management Quotes",
    dom: React.memo(RteArrayCard),
    displayName: "Management Quotes",
  },
  {
    cardType: "Methodology",
    dom: React.memo(Methodology),
    displayName: "Qualification",
  },
  {
    cardType: "Recommended Actions",
    dom: React.memo(RecommendedActions),
    displayName: "Recommended Actions",
  },
  {
    cardType: "Business Challenges",
    dom: React.memo(RteArrayCard),
    displayName: "Business Challenges",
  },
  {
    cardType: "Strategic Initiatives",
    dom: React.memo(RteArrayCard),
    displayName: "Strategic Initiatives",
  },
  {
    cardType: "Research Sources",
    dom: React.memo(SingleRteCard),
    displayName: "Sources",
  },
  {
    cardType: "Technology Landscape",
    dom: React.memo(TechnologyLandscape),
    displayName: "Technology & Solutions Landscape",
  },
  {
    cardType: "Competition Intelligence",
    dom: React.memo(CompetitionIntelligence),
    displayName: "Competition Intelligence",
  },
  {
    cardType: "Operations",
    dom: React.memo(Operations),
    displayName: "Operations",
  },
  {
    cardType: "Flash Card",
    dom: React.memo(FlashCard),
    displayName: "Flash Card",
  },
  {
    cardType: "Annual Reports",
    dom: React.memo(AnnualReports),
    displayName: "Annual Reports Summary",
  },
  {
    cardType: "Company Insights",
    dom: React.memo(CompanyInsights),
    displayName: "Company Insights",
  },
  {
    cardType: "Strategic Snapshot",
    dom: React.memo(SpSiBc),
    displayName: "Strategic Snapshot",
  },
  {
    cardType: "Quarterly Earnings Calls",
    dom: React.memo(QuarterlyEarnings),
    displayName: "Quarterly Earnings Summary",
  },
  {
    cardType: "Discovery",
    dom: React.memo(Discovery),
    displayName: "Discovery",
  },
  {
    cardType: "Signals",
    dom: React.memo(Signals),
    displayName: "Signals",
  },
];

function deepCopy(src) {
  return JSON.parse(JSON.stringify(src));
}

function getCardsDisplay(ucards) {
  // Keep a local copy of cards
  var cards = deepCopy(ucards);
  let cardType = null;
  cards.map((row, index) => {
    cardType = cardTypes.find((ctRow) => {
      return ctRow.cardType === row.cardType;
    });
    if (cardType) {
      row.dom = cardType.dom;
      if (!row.displayName) {
        row.displayName = cardType.displayName;
      }
    }
    return row;
  });
  return cards;
}

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
    paddingBottom: "48px",
    // backgroundImage: "linear-gradient(to right, #DDD, #EEE, #EEE , #FFF, #EEE, #EEE, #DDD)"
  },
  separator: {
    height: "30px",
    backgroundColor: "",
    textAlign: "center",
  },
  leftCardspace: {
    display: "inline-block",
    width: "10%",
  },
  cardcontainer: {
    position: "relative",
    display: "inline-block",
    width: "100%",
    padding: "10px 40px 10px 40px",
    paddingBottom: "20px",
    textAlign: "center",
  },
  rightCardspace: {
    display: "inline-block",
    width: "10%",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  flexCard: {
    alignSelf: "stretch",
  },
  companyLogo: {
    width: "200px",
  },
  stackImage: {
    width: "18px",
    marginRight: "14px",
  },
  modifyButton: {
    width: "150px",
    height: "42px",
    fontWeight: 400,
    textTransform: "none",
    float: "right",
    color: "#333333",
    fontSize: "14px",
    borderRadius: "8px",
    opacity: 0.5,
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.03)",
      opacity: 1,
    },
    "&:active": {
      backgroundColor: "rgba(0,0,0,0.1)",
    },
  },
  selectorStyle: {
    "& .MuiInputBase-input": {
      borderRadius: 0,
      position: "relative",
      backgroundColor: "#fff",
      border: "none",
      fontSize: "0.875rem",
      //  textAlign: "left"
    },
    "&:focus": {
      borderColor: "none",
      border: "none",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "none", // Semi-transparent underline
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: "none", // Solid underline on hover
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "none", // Solid underline on focus
    },
    "& .MuiInput-root:after": {
      borderBottomColor: "none", // Solid underline on focus
    },
  },
  selectionButtons: {
    textTransform: "none",
    marginTop: 0,
    height: "35px",
    width: "100px",
  },
  closeIcon: {
    display: "flex",
    position: "relative",
    justifyContent: "flex-start",
    margin: "15px",
    fontWeight: "700",
    left: "19px",
    marginTop: "5px",
    marginBottom: "0px",
    marginLeft: "auto",
    marginRight: "27px",
    color: "rgba(0,0,0,0.2)",
  },
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-root": {
    display: "inline-flex",
    flexWrap: "wrap",
    justifyContent: "start",
  },
  "& .MuiToggleButtonGroup-grouped": {
    margin: "5px",
    border: "none",
    borderRadius: "5px",
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: "5px",
      marginLeft: "0px",
      borderLeft: "0px",
    },
    "&:first-of-type": {
      borderRadius: "5px",
      marginLeft: "0px",
      borderLeft: "0px",
    },
  },
}));

function sortDisplayName(a, b) {
  if (a.displayName < b.displayName) return -1;
  if (a.displayName > b.displayName) return 1;
  return 0;
}

function CardSpace(props) {
  const elementRef = useRef();
  const [viewedTimer, setViewedTimer] = React.useState(null);
  const [isVisible, setVisible] = React.useState(false);
  const [observer, setObserver] = React.useState(
    new IntersectionObserver(
      ([entry]) => {
        setVisible(entry.isIntersecting);
      },
      { rootMargin: "0px" }
    )
  );

  const [firstTime, setFirstTime] = React.useState(true);
  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
      let cardIndex = elementRef.current.getAttribute("cardindex");
      if (!props.usercards[cardIndex].viewed) {
        setTimeout(function () {
          elementRef.current && observer.observe(elementRef.current);
        }, 1000); // Delay observing for 1 second
        return () => {
          if (elementRef.current) observer.unobserve(elementRef.current);
          if (viewedTimer) clearTimeout(viewedTimer);
        };
      }
    }
  }, [firstTime, isVisible, viewedTimer]);

  if (isVisible) {
    let cardIndex = elementRef.current.getAttribute("cardindex");
    if (!props.usercards[cardIndex].viewed && !viewedTimer) {
      observer.unobserve(elementRef.current);
      props.usercards[cardIndex].viewed = true;
      let localViewTimer = setTimeout(function () {
        // Call API to set viewed status:
        props.updatesettings(props.usercards);
      }, 2000); // Delay updating state for 2 seconds
      setViewedTimer(localViewTimer);
    }
  }

  let { usercards, updatesettings, ...other } = props;

  return (
    <div {...other} ref={elementRef}>
      {props.children}
    </div>
  );
}

const CardSpaceMemo = React.memo(CardSpace);

export default function OPXResearch(props) {
  const ops = props.opportunity;
  const classes = useStyles();

  const [usercards, setUsercards] = React.useState(null);
  const [cards, setCards] = React.useState(null);
  const [cardMenu, setCardMenu] = React.useState(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [cardsToggle, setCardsToggle] = React.useState(() => []);
  const [isDownloading, setIsDownloading] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [downloadCardTypes, setDownloadCardTypes] = React.useState(() => []);
  const [slideFormat, setSlideFormat] = React.useState("pptx");
  const [downloadButtonDisabled, setDownloadButtonDisabled] =
    React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState("");
  const cardRefs = useRef([]);
  const downloadButtonRef = useCallback((node) => {
    if (node) {
      // window.location won't trigger React rendering on changes like useLocation hook would but w/e
      const downloadParam = new URLSearchParams(window.location.search).get(
        "download"
      );
      if (downloadParam) {
        handleCardClick({ currentTarget: node }, "download");
      }
    }
  }, []);

  // Either collapse or expand, used to determine the navigation helper button action.
  const [toggleAction, setToggleAction] = React.useState("expand");

  // Usage: downloadCardAsImage(cardId). cardId must be present in the cards array.
  const downloadCardAsImage = (cardId, isImageCard) => {
    let filteredRefs = cardRefs.current.filter(function (element) {
      return element !== undefined;
    });
    let item = filteredRefs.find((el) => {
      return el.id === cardId;
    });
    if (item) {
      return exportAsImage(item.el, isImageCard);
    } else {
      console.log("No image with ID " + cardId + " found.");
      return null;
    }
  };

  const getOpportunityResearch = (id) => {
    // Call API

    axios
      .get(rule5properties.getOpportunityList + "/" + id + "/research")
      .then((response) => {
        let cardList = response.data;

        if (typeof cardList === "object" && Array.isArray(cardList)) {
          let peopleCardId = null;
          let sellerCompetitionCardId = null;
          let managementQuotesCardId = null;

          cardList.forEach((element) => {
            if (element.cardType === "People") {
              peopleCardId = element.cardId;
            }
            if (element.cardType === "Seller Competition") {
              sellerCompetitionCardId = element.cardId;
            }
            if (element.cardType === "Management Quotes") {
              managementQuotesCardId = element.cardId;
            }
          });

          cardList.forEach((element) => {
            if (element.cardType === "Flash Card") {
              element.peopleCardId = peopleCardId;
              element.managementQuotesCardId = managementQuotesCardId; // For info included as part of People card
            }
            if (element.cardType === "Methodology") {
              element.peopleCardId = peopleCardId;
              element.sellerCompetitionCardId = sellerCompetitionCardId;
            }
            if (element.cardType === "People") {
              element.managementQuotesCardId = managementQuotesCardId;
            }
          });

          setUsercards(cardList);
          setCards(getCardsDisplay(cardList));
          // Only for menu.  DOM is not needed.
          setCardMenu(getCardsDisplay(deepCopy(cardList)));
        } else {
          // API call failed
          setSnackbarMessage(
            "There was a problem retrieving Opportunity Research (1)"
          );
          setOpenSnackbar(true);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
          setSnackbarMessage(
            "There was a problem retrieving Opportunity Research (2)"
          );
          setOpenSnackbar(true);
        }
      });
  };

  const updateUserPreference = (id, cardPrefs) => {
    // Call API

    let updateData = {
      configs: cardPrefs,
    };
    axios
      .patch(
        rule5properties.getOpportunityList + "/" + id + "/research-configs",
        updateData
      )
      .then((response) => {})
      .catch((error) => {
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
          setSnackbarMessage("There was a problem updating user preferences");
          setOpenSnackbar(true);
        }
      });
  };

  useEffect(() => {
    getOpportunityResearch(ops.id);
  }, [ops.id]);

  // Check if all cards are expanded or collapsed
  const checkCollapsedCount = (cards) => {
    let collapseCount = 0;
    let visibleCount = 0;
    cards.forEach((card) => {
      if (!card.hide) {
        if (card.collapse) {
          collapseCount++;
        }
        visibleCount++;
      }
    });
    if (collapseCount === 0) {
      setToggleAction("collapse");
    }
    if (collapseCount >= visibleCount) {
      setToggleAction("expand");
    }
  };

  // targetScrolledRef will determine if the location hash target has already been scrolled to.
  let targetScrolledRef = useRef(false);
  useEffect(() => {
    if (cards) checkCollapsedCount(cards);
    if (
      window.location.hash?.length > 0 &&
      cards?.length > 0 &&
      !targetScrolledRef.current
    ) {
      const found = cards.find((el) => {
        return (
          cardTypeToId(el.cardType) ===
          window.location.hash.substring(1).toLowerCase()
        );
      });
      if (found) {
        setTimeout(() => {
          targetScrolledRef.current = true;
          let filteredRefs = cardRefs.current.filter(function (element) {
            return element !== undefined;
          });
          let target = filteredRefs.find((el) => {
            return el.id === found.cardId;
          });
          target.el.scrollIntoView({ behavior: "auto" });
          // If the card is collapsed, expand it.
          if (found.collapse) {
            toggleExpanded(found.cardId);
          }
        }, 1500);
      }
    }
  }, [cards]);

  // Ensures that whenever usercards are modified, the order of the card menu is reflected.
  useEffect(() => {
    if (usercards) {
      var localCards = deepCopy(getCardsDisplay(usercards));
      setCardMenu(localCards);
    }
  }, [usercards]);

  const updateSettings = (ucards) => {
    // Call API to update the usercard
    updateUserPreference(ops.id, ucards);
    setUsercards(ucards);
    setCards(getCardsDisplay(ucards));
  };

  // Menu Start
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleCardClick = (event, button) => {
    setAnchorEl({ ...anchorEl, [button]: event.currentTarget });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = (rowCardId) => {
    var local = usercards;
    var card = local.find((ctRow) => {
      return ctRow.cardId === rowCardId;
    });
    if (card) {
      if (card.hide) card.hide = false;
      else card.hide = true;

      updateSettings(local);
      // Only for menu.  DOM is not needed.
      var localCards = deepCopy(getCardsDisplay(local));
      setCardMenu(localCards);
    }
  };

  // Toggle a card to be expanded or collapsed.
  const toggleExpanded = (rowCardId) => {
    var local = usercards;
    var card = local.find((ctRow) => {
      return ctRow.cardId === rowCardId;
    });
    if (card) {
      if (card.collapse) card.collapse = false;
      else card.collapse = true;
      updateSettings(local);
    }
  };

  // Expands all cards.
  const expandAll = () => {
    var local = usercards.map((card) => {
      card.collapse = false;
      return card;
    });
    setUsercards(local);
    updateSettings(local);
  };

  // Collapse all cards.
  const collapseAll = () => {
    var local = usercards.map((card) => {
      card.collapse = true;
      return card;
    });
    setUsercards(local);
    updateSettings(local);
  };

  // Expand / collapse all cards, depending on the current state of cards.
  const toggleAll = () => {
    if (toggleAction === "expand") {
      expandAll();
    }
    if (toggleAction === "collapse") {
      collapseAll();
    }
  };

  const handleToggleCards = (event, card) => {
    setCardsToggle(card);
    if (card.length > 0) {
      setDownloadButtonDisabled(false);
    } else {
      setDownloadButtonDisabled(true);
    }
  };

  // Menu End
  const checkClass = (dom, className) => {
    if (dom && dom.classList && dom.classList.contains(className)) {
      return true;
    } else return false;
  };

  const findIndex = (cards, index) => {
    return cards.findIndex((ctRow) => {
      return ctRow.index === index;
    });
  };

  const handleSelectAllCards = (event, cardMenu) => {
    let cardList = [];
    cardMenu.forEach((row, index) => {
      if (!row.hide) cardList.push(row.cardId);
    });
    setCardsToggle(cardList);
    setDownloadButtonDisabled(false);
  };

  const handleClearAllCards = (event) => {
    setCardsToggle([]);
    setDownloadButtonDisabled(true);
  };

  const handleDragEnd = (result) => {
    if (result.source.index !== result.destination.index) {
      let modifiedCards = [...cards];
      modifiedCards.splice(result.source.index, 1);
      modifiedCards.splice(
        result.destination.index,
        0,
        cards[result.source.index]
      );
      setCards(getCardsDisplay(modifiedCards));
      // The cards array needs to be converted to an object with indices to persist settings with endpoint (not exactly
      // sure why this is the case instead of an ordered array).
      let newConfig = modifiedCards.map((card, newIndex) => {
        return { ...card, index: newIndex };
      });
      updateSettings(newConfig);
    } else return;
  };

  const handleDownloadCards = (event, opportunityId, companyName, endpoint) => {
    //Map of CardId to DisplayNames
    setIsDownloading(endpoint);
    setErrorMessage("");

    let promiseImages = [];
    let imageCards;
    let textCards = [];

    axios
      .get(rule5properties.getCardTypes)
      .then((response) => {
        let downloadCardTypeMap;

        if (downloadCardTypes.length === 0) {
          setDownloadCardTypes(response.data);

          downloadCardTypeMap = response.data.reduce(function (map, obj) {
            map[obj.type] = obj.downloadFormat;
            return map;
          }, {});
        } else {
          downloadCardTypeMap = downloadCardTypes.reduce(function (map, obj) {
            map[obj.type] = obj.downloadFormat;
            return map;
          }, {});
        }

        //Map of Cards to DownloadType

        let cardMap = cardMenu.reduce(function (map, obj) {
          map[obj.cardId] = obj.cardType;
          return map;
        }, {});

        cardsToggle.map((cardId) => {
          let cardName = cardMap[cardId];
          if (downloadCardTypeMap[cardName] === "Text") {
            // Attempt to download card as image - if there are no images present, length of images will be 0.
            let images = downloadCardAsImage(cardId, false);
            textCards.push({ cardId: cardId });
            promiseImages.push(images);
          }
        });

        imageCards = cardsToggle.filter((card) => {
          let cardName = cardMap[card];
          return downloadCardTypeMap[cardName] === "Image";
        });

        let sectionedCardImages = [];

        let cardExpandState = new Map();

        cards.map((card) => {
          cardExpandState.set(card.cardId, card.collapse);
        });

        if (imageCards !== 0) {
          imageCards.forEach((cardId, index) => {
            let untoggle = false;
            if (cardExpandState.get(cardId) === true) {
              toggleExpanded(cardId);
              untoggle = true;
            }
            let images = downloadCardAsImage(cardId, true);
            promiseImages.push(images);
            if (untoggle) {
              toggleExpanded(cardId);
            }
          });
        }

        let formData = new FormData();

        Promise.all(promiseImages)
          .then((imageArray) => {
            // imageArray is an array of arrays of images.
            imageArray.forEach((cardSections, indexOuter) => {
              // Images in text cards are first in the promiseImages array.
              if (indexOuter < textCards.length) {
                // Specify the number of images in a text card.
                textCards[indexOuter]["imageCount"] = cardSections.length;
              }
              // cardSections is an array of images in a single card.
              let idOfImageSections = imageCards[indexOuter - textCards.length];
              cardSections.forEach((section, indexInner) => {
                let blobSection = dataURItoBlob(section);
                const file = new File([blobSection], indexInner + ".png", {
                  type: "image/png",
                  lastModified: new Date(),
                });
                formData.append("images", file);
                // Add image card ID to image cards ID array.
                if (indexOuter >= textCards.length) {
                  sectionedCardImages.push(idOfImageSections);
                }
              });
            });

            let cardDataJson = {};

            cardDataJson["downloadFormat"] = slideFormat;

            cardDataJson["opportunityId"] = opportunityId;
            cardDataJson["researchUrl"] = window.location.href;

            if (imageCards.length >= 1) {
              cardDataJson["imageCardIds"] = sectionedCardImages;
            }

            if (textCards.length >= 1) {
              cardDataJson["textCardIds"] = textCards;
            }

            formData.append("cardData", JSON.stringify(cardDataJson));

            axios
              .post(endpoint, formData, {
                responseType: "blob",
                headers: { "Content-Type": "multipart/form-data" },
              })
              .then(async (response) => {
                if (response.status === 200) {
                  if (endpoint === rule5properties.getExportApi) {
                    const url = window.URL.createObjectURL(
                      new Blob([response.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                      "download",
                      companyName + "." + slideFormat
                    ); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                  }

                  setProgress(0);
                  setIsDownloading(false);
                } else {
                  const error = JSON.parse(await response.data.text());
                  console.log(error);
                  setProgress(0);
                  setIsDownloading(false);
                  setErrorMessage(
                    "An error occurred while downloading. Please notify rule5 support or send feedback from the app."
                  );
                  console.log("Download API Failed");
                }
              })
              .catch((error) => {
                setIsDownloading(false);
                if (error.response) {
                  console.log(error.toJSON());
                  console.log(error.response.status);
                  console.log(error.response.data);
                  console.log("Error retrieving card type data");
                  setErrorMessage(
                    "An error occurred while downloading. Please notify rule5 support or send feedback from the app."
                  );
                }
              });
          })
          .catch((error) => {
            setIsDownloading(false);
            console.log("Promise Error: " + error);
          });
      })
      .catch((error) => {
        setIsDownloading(false);
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
          console.log("Error retrieving card type data");
          setErrorMessage(
            "An error occurred while downloading. Please notify rule5 support or send feedback from the app."
          );
        }
      });
  };

  const handleSlideFormat = (event) => {
    setSlideFormat(event.target.value);
  };

  if (!cards || !cardMenu) {
    // Loading...
    return <div className={classes.container}></div>;
  } else {
    return (
      <div className={classes.container}>
        <NavigationHelper
          opportunity={props.opportunity}
          toggleAll={toggleAll}
          toggleAction={toggleAction}
          cards={cards}
          cardRefs={cardRefs}
        />
        <div style={{ width: "90%", margin: "-58px auto 16px auto" }}>
          <Button
            disableRipple
            className={classes.modifyButton}
            onClick={(e) => handleCardClick(e, "cards")}
          >
            <img
              src={stackIcon}
              alt="Available Cards"
              className={classes.stackImage}
            />
            Manage Cards
          </Button>
          <Button
            className={classes.modifyButton}
            onClick={(e) => handleCardClick(e, "download")}
            startIcon={<DownloadIcon />}
            ref={downloadButtonRef}
          >
            Download Cards
          </Button>
          <AddToAgent company={ops?.account} />
        </div>
        <Popover
          id="download-popup"
          anchorEl={anchorEl && anchorEl["download"]}
          open={Boolean(anchorEl && anchorEl["download"])}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Container maxWidth="false" disableGutters sx={{ width: "700px" }}>
            <Stack sx={{ display: "block", alignItems: "center" }}>
              <div style={{ display: "flex" }}>
                <Typography
                  sx={{
                    display: "flex",
                    position: "relative",
                    justifyContent: "flex-start",
                    margin: "15px",
                    fontWeight: "700",
                    left: "19px",
                    marginTop: "15px",
                    marginBottom: "0px",
                  }}
                  variant="body"
                >
                  Download Cards
                </Typography>
                <IconButton className={classes.closeIcon} onClick={handleClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
              <Box
                sx={{
                  display: "flex",
                  position: "relative",
                  left: "19px",
                  alignItems: "center",
                  marginBottom: "5px",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "15px",
                    fontWeight: "400",
                  }}
                  variant="subtitle2"
                >
                  Select the card to download by clicking on the card names.
                </Typography>
                <div style={{ marginLeft: "25px", display: "flex" }}>
                  <Button
                    disableRipple
                    variant="contained"
                    size="small"
                    className={classes.selectionButtons}
                    component="label"
                    onClick={handleClearAllCards}
                  >
                    Clear All
                  </Button>
                  <div style={{ margin: "10px" }} />
                  <Button
                    disableRipple
                    variant="contained"
                    size="small"
                    className={classes.selectionButtons}
                    component="label"
                    onClick={(e) => handleSelectAllCards(e, cardMenu)}
                  >
                    Select All{" "}
                  </Button>
                </div>
              </Box>
              <StyledToggleButtonGroup
                value={cardsToggle}
                orientation="horizontal"
                fullWidth
                color="primary"
                onChange={handleToggleCards}
                aria-label="text-formatting"
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  padding: "20px",
                  columnGap: "20px",
                }}
              >
                {cards.map(
                  (row, index) =>
                    !row.hide && (
                      <ToggleButton
                        key={row.displayName}
                        value={row.cardId}
                        aria-label={row.displayName}
                        disableFocusRipple
                        size="small"
                        sx={{
                          order: row.index,
                          textTransform: "none",
                          textAlign: "center",
                        }}
                      >
                        {row.displayName}
                      </ToggleButton>
                    )
                )}
              </StyledToggleButtonGroup>
              <Box
                sx={{
                  display: "block",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  position: "relative",
                  left: "22px",
                  minWidth: "100%",
                  maxWidth: "100%",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginTop: "15px",
                    marginBottom: "5px",
                    marginLeft: "15px",
                    fontWeight: "400",
                  }}
                  variant="subtitle2"
                >
                  Format
                </Typography>
                <FormControl
                  sx={{
                    display: "block",
                    alignItems: "flex-start",
                    flexWrap: "wrap",
                    flexDirection: "column",
                    width: "100%",
                    fontSize: "10px",
                    left: "19px",
                  }}
                >
                  <RadioGroup defaultValue="pptx" name="radio-download-group">
                    <FormControlLabel
                      value="pdf"
                      control={<Radio />}
                      onChange={handleSlideFormat}
                      label={
                        <Typography sx={{ fontSize: "14px" }}>
                          PDF Document (.pdf)
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="pptx"
                      control={<Radio />}
                      onChange={handleSlideFormat}
                      label={
                        <Typography sx={{ fontSize: "14px" }}>
                          Microsoft PowerPoint (.pptx)
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "inline-flex",
                  width: "100%",
                  marginLeft: "20px",
                }}
              >
                <Typography variant="body2" sx={{ color: "#e64539" }}>
                  {errorMessage}
                </Typography>
              </Box>
              {isDownloading ? (
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "20px",
                  }}
                >
                  <Stack
                    sx={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress size="25px" />
                    <Typography variant="subtitle2">{`${
                      isDownloading === rule5properties.exportEmail
                        ? "Emailing…"
                        : "Downloading…"
                    }`}</Typography>
                  </Stack>
                </Box>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "20px",
                    gap: "20px",
                  }}
                >
                  <Button
                    disableRipple
                    variant="contained"
                    sx={{
                      textTransform: "none",
                    }}
                    disabled={downloadButtonDisabled}
                    component="label"
                    onClick={(e) =>
                      handleDownloadCards(
                        e,
                        props.opportunity.id,
                        props.opportunity.Account,
                        rule5properties.getExportApi
                      )
                    }
                  >
                    Download
                  </Button>
                  <Button
                    disableRipple
                    variant="contained"
                    sx={{
                      textTransform: "none",
                    }}
                    disabled={downloadButtonDisabled}
                    component="label"
                    onClick={(e) =>
                      handleDownloadCards(
                        e,
                        props.opportunity.id,
                        props.opportunity.Account,
                        rule5properties.exportEmail
                      )
                    }
                  >
                    Email
                  </Button>
                </div>
              )}
            </Stack>
          </Container>
        </Popover>
        <Menu
          id="card-menu"
          anchorEl={anchorEl && anchorEl["cards"]}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: 0,
            horizontal: 30,
          }}
          open={Boolean(anchorEl && anchorEl["cards"])}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          PaperProps={{
            elevation: 2,
          }}
        >
          <MenuList dense>
            {cardMenu.map((row, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={(e) => {
                    handleMenuClick(row.cardId);
                    console.log(row);
                  }}
                >
                  <Switch
                    checked={!row.hide}
                    label={row.displayName}
                    size="small"
                  />
                  &nbsp;&nbsp;{row.title || row.displayName}
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
        {usercards.length > 0 ? (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppableCards">
              {(provided) => (
                <Stack
                  alignItems="center"
                  className={classes.flexContainer}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {cards.map((row, cardIndex) => {
                    let Component = row.dom;
                    if (!row.hide && Component) {
                      return (
                        <Draggable
                          key={row.cardId + "-id"}
                          draggableId={row.cardId + "-id"}
                          index={cardIndex}
                        >
                          {(provided) => (
                            <div
                              className={classes.cardcontainer}
                              {...provided.draggableProps}
                              // {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <CardSpaceMemo
                                id={cardTypeToId(row.cardType)}
                                className={classes.flexCard}
                                parmindex={usercards[cardIndex].index}
                                cardindex={cardIndex}
                                usercards={usercards}
                                updatesettings={updateSettings}
                              >
                                <div // the reference to the card container - content is the second child in second layer
                                  ref={(el) =>
                                    (cardRefs.current[cardIndex] = {
                                      id: row.cardId,
                                      el: el,
                                    })
                                  }
                                >
                                  <Component
                                    dragHandleProps={{
                                      ...provided.dragHandleProps,
                                    }}
                                    cardId={row.cardId}
                                    cardType={row.cardType}
                                    peopleCardId={row.peopleCardId}
                                    sellerCompetitionCardId={
                                      row.sellerCompetitionCardId
                                    }
                                    managementQuotesCardId={
                                      row.managementQuotesCardId
                                    }
                                    opportunity={ops}
                                    viewed={row.viewed}
                                    expanded={!row.collapse}
                                    toggleExpanded={() => {
                                      toggleExpanded(row.cardId);
                                    }}
                                    closeCard={() => {
                                      handleMenuClick(row.cardId);
                                    }}
                                    // {...props}
                                  />
                                </div>
                              </CardSpaceMemo>
                            </div>
                          )}
                        </Draggable>
                      );
                    } else return null;
                  })}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ flexGrow: "1", m: 10 }}
          >
            <Typography variant="h5" sx={{ opacity: 0.5, textAlign: "center" }}>
              This research request is in process and we will notify you as soon
              as it becomes available.
            </Typography>
          </Stack>
        )}
        <div style={{ height: "60px" }} />
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </div>
    );
  }
}

function NavigationHelper(props) {
  const { cards, cardRefs, opportunity } = props;

  const displayContext = useDisplayContext();
  const history = useHistory();

  const [jumpMode, setJumpMode] = React.useState(false);
  const navRef = useRef(null);

  function handleClickOutside(event) {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setJumpMode(false);
    }
  }

  let sortedCards = [...cards];
  sortedCards?.sort((a, b) => a.title.localeCompare(b.title));
  document.addEventListener("mousedown", handleClickOutside);

  return (
    <Card
      ref={navRef}
      variant="outlined"
      sx={{
        borderRadius: "8px",
        position: "fixed",
        zIndex: 998,
        bottom: 0,
        right: 0,
        my: 3,
        mx: 4,
        p: 2,
      }}
    >
      <Collapse in={jumpMode}>
        <Stack sx={{ mb: 1 }}>
          <Typography
            variant="h5"
            sx={{ mb: 2, mt: 1, mr: 3, fontWeight: 500, textAlign: "right" }}
          >
            Card Navigation
          </Typography>
          {sortedCards?.map((card, index) => {
            if (!card.hide)
              return (
                <Button
                  disableRipple
                  key={index}
                  variant="text"
                  fullWidth
                  sx={{
                    alignSelf: "flex-end",
                    maxWidth: "280px",
                    textTransform: "none",
                    textAlign: "end",
                    py: 1,
                    px: 3,
                    color: "rgba(0,0,0,0.6)",
                  }}
                  style={{ justifyContent: "flex-end" }}
                  onClick={() => {
                    setJumpMode(false);
                    let filteredRefs = cardRefs.current.filter(function (
                      element
                    ) {
                      return element !== undefined;
                    });
                    let target = filteredRefs.find((el) => {
                      return el.id === card.cardId;
                    });
                    target.el.scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  {card.title}
                </Button>
              );
            else return null;
          })}
        </Stack>
      </Collapse>
      <Stack sx={{ height: "100%" }} direction="row" alignItems="center">
        {!(
          displayContext.presetCompany.opportunityId &&
          displayContext.presetCompany.companyId &&
          displayContext.presetCompany.functionalArea
        ) && (
          <Button
            sx={{
              borderRadius: "6px",
              px: 2,
              py: 1,
            }}
            onClick={() => {
              history.push(
                `/main/chat?companyId=${
                  opportunity.account.companyId
                }&functionalArea=${encodeURIComponent(
                  opportunity.functionalArea
                )}`
              );
            }}
          >
            <ForumIcon sx={{ mr: 1, mb: "1px" }} fontSize="small" />
            Start a conversation
          </Button>
        )}
        <Button
          variant="text"
          disableRipple
          sx={{
            textTransform: "none",
            borderRadius: "6px",
            pr: 2,
            pl: 1,
            py: 1,
          }}
          onClick={props.toggleAll}
        >
          {props.toggleAction === "expand" ? (
            <>
              <UnfoldMoreIcon sx={{ mr: 1, mb: "1px" }} /> Expand All{" "}
            </>
          ) : (
            <>
              <UnfoldLessIcon sx={{ mr: 1, mb: "1px" }} /> Collapse All{" "}
            </>
          )}
        </Button>
        <Button
          variant="text"
          disableRipple
          sx={{
            textTransform: "none",
            borderRadius: "6px",
            px: 2,
            py: 1,
          }}
          onClick={() => {
            setJumpMode((prevMode) => !prevMode);
          }}
        >
          <MoveDownIcon sx={{ mr: 1, mb: "1px" }} />
          Jump to…
        </Button>
      </Stack>
    </Card>
  );
}
