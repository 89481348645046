import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { rule5properties } from "../properties";
import React, { useState } from "react";
import { debounce } from "lodash";
import useSnack from "../context/Snack";
import { isSuccessStatus } from "../common/RequestUtils";

/** Factory to create react query agent fetcher. */
const createAgentHook = (agentType) => {
  const fetchAgents = async (searchValue) => {
    const params = new URLSearchParams();
    params.append("type", agentType);
    if (searchValue) {
      params.append("searchValue", encodeURIComponent(searchValue));
    }
    const response = await axios.get(
      `${rule5properties.agents}?${params.toString()}`
    );
    return response.data;
  };

  return (initialSearchValue = "") => {
    // return (searchValue = "", isProcessingAgents) => {
    // const [searchValue, setSearchValue] = React.useState(initialSearchValue);
    const queryClient = useQueryClient();
    const [staleTime, setStaleTime] = React.useState(5 * 60 * 1000); // Default to 5 minutes
    console.log("initialSearchValue:" + initialSearchValue + ".");

    // const [immediateSearchValue, setImmediateSearchValue] =
    //   React.useState(initialSearchValue);
    // const [debouncedSearchValue, setDebouncedSearchValue] =
    //   React.useState(initialSearchValue);

    // const searchThrottleTimeout = React.useRef();
    // React.useEffect(() => {
    //   clearTimeout(searchThrottleTimeout.current);
    //   if (immediateSearchValue === "") {
    //     setDebouncedSearchValue(immediateSearchValue);
    //     return;
    //   }
    //   if (immediateSearchValue.trim().length !== 1) {
    //     searchThrottleTimeout.current = setTimeout(() => {
    //       setDebouncedSearchValue(immediateSearchValue);
    //     }, 300);
    //   }
    // }, [immediateSearchValue, setDebouncedSearchValue]);

    // const debouncedSetSearch = React.useCallback(
    //   debounce((value) => {
    //     setDebouncedSearchValue(value);
    //   }, 300),
    //   []
    // );

    // const debouncedSetSearch = React.useMemo(
    //   () =>
    //     debounce((value) => {
    //       setDebouncedSearchValue(value);
    //     }, 300),
    //   []
    // );

    // React.useEffect(() => {
    //   debouncedSetSearch(immediateSearchValue);
    // }, [immediateSearchValue, debouncedSetSearch]);

    // console.log("debouncedVal: " + debouncedSearchValue);
    const queryKey = [agentType, initialSearchValue];
    // const queryKey = [agentType, searchValue];

    // const hasProcessingStatus = React.useCallback((data) => {
    //   return (
    //     Array.isArray(data) &&
    //     data.some((row) => row.monitorStatus === "Processing")
    //   );
    // }, []);

    // const updateStaleTime = React.useCallback(
    //   (data) => {
    //     const newStaleTime = hasProcessingStatus(data)
    //       ? 30 * 1000
    //       : 5 * 60 * 1000;
    //     console.log("newStaleTIme: " + newStaleTime);
    //     setStaleTime(newStaleTime);
    //     queryClient.setQueryDefaults(queryKey, { staleTime: newStaleTime });
    //   },
    //   [hasProcessingStatus, queryClient, queryKey]
    // );

    const {
      data: rows,
      isFetching,
      error,
      refetch,
      // } = useQuery(queryKey, () => fetchAgents(searchValue), {
    } = useQuery(
      queryKey,
      React.useCallback(
        () => fetchAgents(initialSearchValue),
        [initialSearchValue]
      ),
      {
        // keepPreviousData: true,
        staleTime: staleTime,
        // onSuccess: updateStaleTime,
        onError: (err) => console.log(err),
        enabled: initialSearchValue.length !== 1,
        // enabled: false, // disable automatic fetching so the below throttling will do it instead
        // enabled: searchValue.length !== 1,
        placeholderData: [],
        // notifyOnChangeProps: ["data", "error"],
        // debounce: 3000,
        refetchInterval: (data) => {
          if (!Array.isArray(data)) {
            return false;
          }
          // Check if any row has status "Processing"
          const hasProcessingStatus = data?.some(
            (row) => row.monitorStatus === "Processing"
          );
          console.log("hasProcessign: " + hasProcessingStatus);
          // If there's a processing status, refetch every 30 seconds (30000 ms)
          // Otherwise, don't auto-refetch
          return hasProcessingStatus ? 30000 : false;
        },
      }
    );

    // const updateSearch = React.useCallback(
    //   (newSearchValue) => {
    //     setSearchValue(newSearchValue);
    //     queryClient.invalidateQueries([agentType]);
    //   },
    //   [queryClient]
    // );

    // const updateSearch = React.useCallback(
    //   debounce((newSearchValue) => {
    //     if (newSearchValue.length !== 1) {
    //       setSearchValue(newSearchValue);
    //       queryClient.invalidateQueries([agentType]);
    //     }
    //   }, 300), // 300ms delay
    //   [queryClient]
    // );

    // const updateSearch = React.useCallback((newValue) => {
    //   setImmediateSearchValue(newValue);
    // }, []);

    // React.useEffect(() => {
    //   console.log("refetching");
    //   refetch();
    //   // clearTimeout(searchThrottleTimeout.current);
    //   // if (searchValue.length === 0) {
    //   //   // For initial load or if user clears search, don't throttle.
    //   //   // refetch();
    //   // } else if (searchValue.trim().length !== 1) {
    //   //   searchThrottleTimeout.current = setTimeout(() => {
    //   //     console.log("refetching");
    //   //     console.log("searchValue: " + searchValue + ".");
    //   //     refetch();
    //   //   }, 300);
    //   // }
    // }, [searchValue, refetch]);

    // const updateSearch = React.useCallback((newSearchValue) => {
    //   console.log("setting search value");
    //   setSearchValue(newSearchValue);
    // }, []);

    // React.useEffect(() => {
    //   let intervalId;
    //   if (isProcessingAgents) {
    //     intervalId = setInterval(() => {
    //       refetch();
    //     }, 30000); // 30 seconds
    //   }
    //   return () => {
    //     if (intervalId) clearInterval(intervalId);
    //   };
    // }, [isProcessingAgents, refetch]);

    return {
      rows,
      isFetching,
      error,
      // searchValue,
      // searchValue: immediateSearchValue,
      // updateSearch,
      refetch,
    };
  };
};

export const useAccountAgents = createAgentHook("Accounts");
export const usePeopleAgents = createAgentHook("People");

export const useAccountAgentsByCompany = (company, enabled) => {
  return useQuery(
    ["companyAgents", company.companyId],
    async () => {
      const response = await axios.get(
        `${rule5properties.agentAccounts}?companyId=${company.companyId}`
      );
      if (!isSuccessStatus(response.status)) {
        throw new Error("Unexpected error loading agents.");
      }
      return response.data;
    },
    {
      staleTime: 5 * 60 * 1000,
      enabled: enabled,
      onError: (error) => {
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
        }
      },
    }
  );
};

export const useSyncedResearchAccounts = () => {
  return useQuery(
    "syncedResearchAccounts",
    async () => {
      const response = await axios.get(rule5properties.syncAccounts);
      if (response.status !== 200 || !response.data) {
        throw new Error("Failed to fetch synced research accounts");
      }
      return response.data;
    },
    {
      select: (data) => data.length > 0,
      onError: (error) => {
        console.error("Error fetching synced research accounts:", error);
      },
    }
  );
};
