import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  ClickAwayListener,
  IconButton,
  Tooltip,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import { useAccountAgents, useAccountAgentsByCompany } from "../api/agents";
import {
  PopperComponent,
  StyledPopper,
  StyledTextfield,
} from "./StyledComponents";
import useSnack from "../context/Snack";
import { useUser } from "../context/UserContext";
import signalsIcon from "./../res/icons/outline/16px_antenna.svg";
import { AgentName } from "../workspaces/monitor/common/monitorColumns";
import axios from "axios";
import { rule5properties } from "../properties";
import { isSuccessStatus } from "./RequestUtils";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const AddToAgent = React.memo(function AddToAgent(props) {
  const { company, onChangeFromParent } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const snackBar = useSnack();
  const user = useUser();

  const open = Boolean(anchorEl);
  const { rows, isFetching: isFetchingOptions } = useAccountAgents();
  // fix bug where this gets called with undefined company in research company
  const {
    data: existingAgents,
    refetch,
    isFetching,
  } = useAccountAgentsByCompany(company, open);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionChange = (newValues) => {
    const prevIds = new Set(existingAgents.map((option) => option.agentId));
    const newIds = new Set(newValues.map((option) => option.agentId));

    // Find added options
    newValues.forEach((option) => {
      if (!prevIds.has(option.agentId)) {
        // addMutation.mutate(option.agentId);
        axios
          .post(rule5properties.addAccounts, {
            agentId: option.agentId?.toString(),
            accountAgents: [
              {
                companyId: company?.companyId,
                companyName: company?.name,
              },
            ],
          })
          .then((resp) => {
            if (resp.status === 200) {
              snackBar.createSnack(
                `Successfully added ${company?.name} to ${option.name}.`
              );
              refetch();
            }
          });
      }
    });

    // Find removed options
    existingAgents.forEach((option) => {
      if (!newIds.has(option.agentId)) {
        // deleteMutation.mutate(option.agentId);
        axios
          .delete(`${rule5properties.agentAccounts}/${option.agentAccountId}`)
          .then((resp) => {
            if (isSuccessStatus(resp.status)) {
              snackBar.createSnack(
                `Successfully removed ${company?.name} from ${option.name}.`
              );
              refetch();
            } else {
              const message = resp.data?.message;
              snackBar.createSnack(
                message ? message : "Unexpected error deleting agent."
              );
            }
          });
      }
    });
  };

  const onChange = (event, newSelectedAgents) => {
    // const n

    if (onChangeFromParent) {
      onChangeFromParent(newSelectedAgents);
    } else {
      handleOptionChange(newSelectedAgents);
      // axios
      //   .post(rule5properties.addAccounts, {
      //     agentId: newSelectedAgents?.agentId?.toString(),
      //     accountAgents: [
      //       {
      //         companyId: company?.companyId,
      //         companyName: company?.name,
      //       },
      //     ],
      //   })
      //   .then((resp) => {
      //     if (resp.status === 200) {
      //       snackBar.createSnack(
      //         `Successfully added ${company?.name} to ${newSelectedAgents.name}.`
      //       );
      //       refetch();
      //     }
      //   });
    }
  };

  if (isFetchingOptions || isFetching) return <div>Loading...</div>;

  const values = existingAgents?.some((agent) => agent.agentId === rows);

  return (
    <>
      <Tooltip title={`Add ${company?.name} to existing agent`}>
        <Button
          size="small"
          onClick={handleClick}
          sx={{
            gap: "12px",
            width: "150px",
            height: "42px",
            fontWeight: 400,
            textTransform: "none",
            float: "right",
            color: "#333333",
            fontSize: "14px",
            borderRadius: "8px",
            opacity: 0.5,
            transition: "0.2s",
            "&:hover": {
              backgroundColor: "rgba(0,0,0,0.03)",
              opacity: 1,
            },
            "&:active": {
              backgroundColor: "rgba(0,0,0,0.1)",
            },
          }}
        >
          <img src={signalsIcon} alt="Available Cards" />
          Enable signals
        </Button>
      </Tooltip>
      <StyledPopper
        anchorEl={anchorEl}
        open={open}
        id={"Card-types-select"}
        placement="top-start"
        sx={{ width: "350px" }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "500px",
              width: "100%",
            }}
          >
            <Autocomplete
              onChange={onChange}
              value={existingAgents}
              loading={isFetching || isFetchingOptions}
              PopperComponent={PopperComponent}
              selectOnFocus
              isOptionEqualToValue={(option, value) => {
                return option.agentId === value.agentId;
              }}
              multiple
              renderTags={() => null}
              // getOptionDisabled={(option) =>
              //   option.syncResearchAccounts ||
              //   existingAgents?.some(
              //     (agent) => agent.agentId === option.agentId
              //   )
              // }
              getOptionLabel={(option) => option.name}
              open
              clearIcon={false}
              clearOnBlur
              handleHomeEndKeys
              // disable
              disableCloseOnSelect={true}
              id="add-to-agents-autocomplete"
              options={!isFetching ? rows : []}
              // popupIcon={""} // Remove the little dropdown arrow
              slotProps={{
                popupIndicator: {
                  onClick: handleClose,
                },
              }}
              noOptionsText="No agents found."
              onClose={(event, reason) => {
                if (reason === "escape") {
                  handleClose();
                }
              }}
              // renderTags={() => {}}
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  style={{
                    display: "flex",
                    // justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component={DoneIcon}
                    sx={{ width: 17, height: 17, mr: "5px", ml: "-2px" }}
                    style={{
                      visibility: selected ? "visible" : "hidden",
                    }}
                  />
                  {/* <Checkbox style={{ marginRight: 8 }} checked={selected} /> */}
                  {/* {option?.name} */}
                  <AgentName
                    name={option.name}
                    syncResearchAccounts={option.syncResearchAccounts}
                  />
                  <IconButton
                    size="small"
                    style={{
                      visibility: selected ? "visible" : "hidden",
                      marginLeft: "auto",
                    }}
                  >
                    <CancelIcon
                      sx={{ width: 18, height: 18 }}
                      fontSize="small"
                    />
                  </IconButton>
                  {/* {!state.selected && (
                    <Tooltip
                      title={
                        state.disabled
                          ? "Option disabled"
                          : `Add ${company?.name} to agent`
                      }
                    >
                      <IconButton
                        size="small"
                        // disabled={disabled}
                        onClick={(event) => {
                          event.stopPropagation();
                          console.log("Icon clicked for:", option.label);
                        }}
                      >
                        {state.disabled ? (
                          <DeleteIcon fontSize="small" />
                        ) : (
                          <AddIcon fontSize="small" />
                        )}
                      </IconButton>
                    </Tooltip>
                  )} */}
                </li>
              )}
              // sx={{ width: "350px" }}
              renderInput={(params) => {
                const { InputLabelProps, ...rest } = params;
                const InputProps = {
                  ...params.InputProps,
                };
                return (
                  <>
                    {/* <CloseBox handleClose={handleClose} /> */}
                    <StyledTextfield
                      autoFocus
                      placeholder={"Search agents…"}
                      {...rest}
                      {...InputProps}
                      {...params}
                    />
                    {/* <div
                      style={{
                        display: "flex",
                        borderBottom: "1px solid gainsboro",
                        justifyContent: "space-between",
                        padding: "5px 10px",
                      }}
                    >
                      <Button
                        disableRipple
                        variant="flat"
                        size="small"
                        disabled={isEmpty(displayedSelection)}
                        color="rgb(107,149,185)"
                        sx={{ color: "#506f8a" }}
                        onClick={() => {
                          setSelectedCardTypes({ types: [], excludeAll: true });
                        }}
                      >
                        Clear all
                      </Button>
                      <Button
                        disableRipple
                        variant="flat"
                        disabled={
                          isEmpty(cardTypes) ||
                          isEqual(sortBy(cardTypes), sortBy(displayedSelection))
                        }
                        size="small"
                        sx={{ color: "#506f8a" }}
                        onClick={() => {
                          setSelectedCardTypes(DEFAULT_CARD_TYPES_OBJ);
                        }}
                      >
                        Select all
                      </Button>
                    </div> */}
                  </>
                );
              }}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
});

export default AddToAgent;

export function CloseBox(props) {
  const { handleClose } = props;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        height: "40px",
      }}
    >
      <IconButton
        sx={{ marginRight: "10px", height: "fit-content" }}
        size="small"
        aria-label="close"
        color="#525252"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  );
}

function sortAndMoveSelectedItems(options, selected) {
  // Create a set for faster lookup.
  const selectedSet = new Set(selected);

  options.sort((a, b) => {
    const aInSelected = selectedSet.has(a);
    const bInSelected = selectedSet.has(b);

    // Move common items to the top.
    if (aInSelected && !bInSelected) {
      return -1;
    } else if (!aInSelected && bInSelected) {
      return 1;
    } else {
      return 0;
    }
  });

  return options;
}

export function getSelectedCardTypesFromConversationPrompt(conversationPrompt) {
  const cardTypes = conversationPrompt?.cardTypes;
  return {
    types:
      cardTypes &&
      !(
        conversationPrompt?.includeAllCards ||
        conversationPrompt?.excludeAllCards
      )
        ? cardTypes
        : [],
    excludeAll: conversationPrompt?.excludeAllCards,
  };
}
