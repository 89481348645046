import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TextField,
} from "@mui/material";
import Toolbar from "../../common/Toolbar";
import Workspace from "../Workspace";
import { JOB_CHANGE_LEADS_COLUMNS } from "./common/monitorColumns";
import PeopleAgentResults from "./PeopleAgentResults";
import { useDialog } from "../../context/DialogContext";
import CreateAgent from "../../modal/CreateAgent";
import { peopleAgentType } from "./common/AgentTypes";
import {
  CancelConfirmButtons,
  ErrLabel,
  StyledLabel,
  StyledTableCell,
  StyledTableRow,
  StyledTextfield,
} from "../../common/StyledComponents";
import { useIntegrationTags } from "../../api/integrations";
import { Controller, useForm } from "react-hook-form";
import PromptBooksAutocomplete from "../chat/PromptBooksAutocomplete";
import axios from "axios";
import { rule5properties } from "../../properties";
import { isSuccessStatus } from "../../common/RequestUtils";

export default function MassOutreach(props) {
  const dialog = useDialog();
  const { data: tags, isLoading: isLoadingTags } = useIntegrationTags();
  const { control, register, handleSubmit, reset, formState, setValue, watch } =
    useForm({ tags: [] });

  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(null);
  const [campaigns, setCampaigns] = React.useState(null);
  const [promptBooksAnchorEl, setPromptBooksAnchorEl] = React.useState(null);

  const handlePromptBooksClose = () => {
    setPromptBooksAnchorEl(null);
  };

  const handlePromptBooksClick = (event) => {
    setPromptBooksAnchorEl(event.currentTarget);
  };

  const CREATE_TITLE = "Create people agent";

  React.useEffect(() => {
    axios
      .get(rule5properties.campaigns)
      .then((resp) => setCampaigns(resp.data));
  }, [formState.isSubmitSuccessful]);

  const promptBooksOpen = Boolean(promptBooksAnchorEl);

  const onSubmit = (updatedOutreachMappings) => {
    let output = updatedOutreachMappings;
    output.promptId = updatedOutreachMappings.prompt?.id;
    delete output.prompt;
    console.log(output);
    setLoading(true);

    // if (output.promptId) {
    axios.post(rule5properties.campaigns, output).then((resp) => {
      setLoading(false);
      if (isSuccessStatus(resp.status)) {
        setError("");
        console.log(resp.data);
        reset();
      } else if (resp.data.message) {
        setError(resp.data.message);
      }
    });
    // }
    // updateMutation.mutate(updatedOutreachMappings);
  };

  if (isLoadingTags) {
    return "Loading...";
  }

  const prompt = watch("prompt");

  console.log("dirty state: " + formState.isDirty);
  console.log("dirty fields: " + JSON.stringify(formState.dirtyFields));

  return (
    <Box sx={{ height: "100vh" }}>
      <Toolbar>
        <Stack direction="row" alignItems="center">
          Generate personalized messages
        </Stack>
      </Toolbar>
      <Workspace>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            width: "600px",
            gap: "20px",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Stack
            sx={{
              width: "600px",
              gap: "20px",
              alignSelf: "center",
              maxHeight: "80vh",
              overflow: "auto",
            }}
          >
            <div>
              <StyledLabel>Campaign name</StyledLabel>
              <StyledTextfield
                {...register("name")}
                placeholder="Enter name…"
                sx={{ width: "100%" }}
              />
            </div>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  onChange={(e, data) => {
                    field.onChange(data);
                  }}
                  multiple
                  id="tags-standard"
                  options={tags || []}
                  // getOptionLabel={(option) => option.title}
                  renderInput={(params) => {
                    const { InputLabelProps, InputProps, ...rest } = params;
                    return (
                      <React.Fragment>
                        <StyledLabel style={{ textAlign: "left" }}>
                          Prospect lists
                        </StyledLabel>
                        <StyledTextfield
                          {...params.InputProps}
                          {...rest}
                          placeholder="Select tags…"
                        />
                        {/* {errors?.currency?.type === "validate" && (
                        <p style={{ color: "red" }}>
                          Currency is required when setting amount fields.
                        </p>
                      )} */}
                      </React.Fragment>
                    );
                  }}
                />
              )}
              name={`tags`}
              type="select"
              control={control}
              // rules={{
              //   required: { value: true, message: "Author is required" },
              // }}
            />
            <div>
              <StyledLabel>Personalization prompt</StyledLabel>
              <StyledTextfield
                // {...register("prompt")}
                sx={{
                  "& .MuiInputBase-input": {
                    cursor: "pointer",
                    maxHeight: "30vh",
                    overflowY: "auto !important",
                  },
                  width: "100%",
                  // maxHeight: "30vh",
                  overflowY: "hidden",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
                onClick={handlePromptBooksClick}
                placeholder="Select prompt…"
                multiline
                value={prompt ? prompt.prompt : ""}
                InputProps={{
                  readOnly: true,
                  style: { cursor: "pointer" },
                }}
              />
            </div>
            {/* <StyledTextfield
            onClick={handlePromptBooksClick}
            placeholder="Select prompt…"
          >
            {prompt ? prompt.prompt : ""}
          </StyledTextfield> */}
            <PromptBooksAutocomplete
              open={promptBooksOpen}
              anchorEl={promptBooksAnchorEl}
              setValue={(newValue) => {
                setValue("prompt", newValue);
              }}
              handleClose={handlePromptBooksClose}
              // setSelectedContents={setSelectedContents}
              // setSelectedCardTypes={setSelectedCardTypes}
            />
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Message options
              </FormLabel>
              <Controller
                name="deliveryOption"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="Generate personalized draft emails"
                      control={<Radio />}
                      label="Generate personalized draft emails"
                    />
                    <FormControlLabel
                      value="Generate personalized messages for sequences"
                      control={<Radio />}
                      label="Generate personalized messages for sequences"
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>
            <Stack sx={{ width: "100%" }} alignItems="center">
              <Collapse in={formState.isDirty}>
                <CancelConfirmButtons
                  loading={loading}
                  sx={{ mt: 1, width: "360px" }}
                  cancelText="Cancel"
                  cancelFunction={() => {
                    reset();
                  }}
                  confirmText="Save changes"
                  confirmDisabled={formState.errors?.length > 0}
                />
              </Collapse>
              {/* {disabled && (
            <Typography sx={{ opacity: 0.7, mt: 2 }}>
              Only organization admins can update Outreach integration details.
            </Typography>
          )} */}
              {error && <ErrLabel sx={{ mt: 1 }}>{error}</ErrLabel>}
            </Stack>
          </Stack>
        </form>
        <CampaignTable campaigns={campaigns} />
      </Workspace>
    </Box>
  );
}

function CampaignTable(props) {
  const { campaigns } = props;

  if (!campaigns?.length > 0) {
    return null;
  }
  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: "none", mt: 4, maxWidth: "800px", alignSelf: "center" }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="campaign table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell align="left">Name</StyledTableCell>
            <StyledTableCell>Prospect list</StyledTableCell>
            {/* <StyledTableCell align="left">Prompt</StyledTableCell> */}
            <StyledTableCell align="left">Message option</StyledTableCell>
            <StyledTableCell align="right">Prospect count</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {campaigns?.map((row, index) => {
            return (
              <StyledTableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <StyledTableCell>{row.campaignName}</StyledTableCell>
                <StyledTableCell>{row.tags?.join(", ")}</StyledTableCell>
                <StyledTableCell align="left">
                  {row.deliveryOption}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.prospectsCount}
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
