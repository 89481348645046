import * as React from "react";
import { useEffect } from "react";
import AuthService from "./user/AuthService";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import LastPage from "@mui/icons-material/LastPage";
import { MenuList, MenuSubList } from "./MenuList";
import WSSearch from "./workspaces/WSSearch";
import WSHome from "./workspaces/WSHome";
import WSOpportunities from "./workspaces/WSOpportunities";
import WSTasks from "./workspaces/WSTasks";
import WSSettings from "./workspaces/WSSettings";
import { makeStyles } from "@mui/styles";
import {
  useLocation,
  useHistory,
  Switch,
  Route,
  Redirect,
  Link,
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import {
  Fade,
  Collapse,
  Divider,
  Stack,
  Card,
  styled,
  Typography,
} from "@mui/material";
import { ALLOWED_ACTIONS, useUser, useUserUpdate } from "./context/UserContext";
import { LoadedAvatar } from "./common/StyledComponents";
import { rule5Env, rule5properties } from "./properties";
import ManageUsers from "./user/ManageUsers";
import WSConnections from "./workspaces/WSConnections";
import { useMenuContext } from "./context/MenuContext";
import { useDisplayContext } from "./context/DisplayContext";
import ManageSfdcAccounts from "./user/ManageSfdcAccounts";
import WSChat from "./workspaces/WSChat";
import { GptConversationProvider } from "./context/GptConversationContext";
import { BrowserView, isBrowser } from "react-device-detect";
import WSPromptBooks from "./workspaces/WSPromptBooks";
import WSContent from "./workspaces/WSContent";
import MonitorAccounts from "./workspaces/monitor/MonitorAccounts";
import MonitorPeople from "./workspaces/monitor/MonitorPeople";
import AccountEvents from "./workspaces/monitor/AccountEvents";
import JobChangeLeads from "./workspaces/monitor/JobChangeLeads";
import { getLandingFromAllowedActions } from "./common/Utils";
import MassOutreach from "./workspaces/monitor/MassOutreach";

const useStyles = makeStyles(() => ({
  drawer: {
    "& .MuiDrawer-paper": {
      background: "#FFFFFF", // theme.drawer.backgroundColor,
    },
    zIndex: "900",
    height: "100vh",
  },
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    userSelect: "none",
  },
  avatarContainer: {
    margin: "2px 0px 0px 2px",
  },
  avatar: {
    cursor: "pointer",
    width: "38px",
    height: "38px",
    transition: "0.2s",
    backgroundColor: "rgba(0,0,0,0.3)",
    "-webkit-transition": "-webkit-filter 500ms linear",
    "&:hover": {
      "-webkit-filter": "brightness(90%)",
    },
  },
  logoLarge: {
    display: "block",
    height: "24px",
    paddingLeft: "8px",
    margin: "12px auto 0px auto",
  },
  s5LogoLarge: {
    display: "block",
    height: "30px",
    paddingLeft: "8px",
    margin: "16px auto 0px auto",
  },
  logoSmall: {
    height: "24px",
    margin: "auto",
  },
  logoLink: {
    cursor: "pointer",
    height: "100%",
    flexGrow: "1",
    transition: "0.2s",
    opacity: 0.6,
    "&:hover": {
      filter:
        "invert(38%) sepia(98%) saturate(744%) hue-rotate(179deg) brightness(85%) contrast(102%)",
      opacity: 1,
    },
  },
  userLink: {
    textDecoration: "underline 0.1em rgba(0,0,0,0)",
    fontWeight: 500,
    color: "rgb(25, 118, 210)",
    transition: "text-decoration-color 0.2s",
    "&:hover": {
      textDecorationColor: "rgb(25, 118, 210)",
    },
    "&:active": {
      color: "rgba(0,0,0,0.5)",
    },
  },
  avatarMenuItem: {
    fontSize: "14px",
    borderRadius: "2px",
    height: "44px",
  },
}));

const openedMixin = (theme) => ({
  width: theme.mainDrawer.width,

  transition: ".2s",
  padding: "0px 16px 0px 16px",
  overflow: "hidden",
});

const closedMixin = (theme) => ({
  transition: ".2s",
  padding: "0px 8px 0px 8px",
  overflowX: "hidden",
  width: `calc(${theme.spacing(9)} + 4px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 4px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: "0px 0px 0px 0px",
  height: theme.toolbar.height,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const MainPage = React.memo(function MainPage(props) {
  const user = useUser();
  const menuContext = useMenuContext();
  const displayContext = useDisplayContext();
  const updateUserContext = useUserUpdate();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [showUserInfo, setShowUserInfo] = React.useState(false);

  const landingPage = React.useRef("opportunities");

  const handleLogout = (event) => {
    AuthService.logout();
    updateUserContext(null);
    history.push("/");
  };

  const toggleDrawer = () => {
    const newStatus = !open;
    menuContext.setOpen(newStatus);
    setOpen(newStatus);
  };

  const classes = useStyles();
  const location = useLocation();

  // Allow user menu to pop up temporarily and refresh on re-click.
  const [userInfoTimeoutId, setUserInfoTimeoutId] = React.useState(null);

  const openAvatarMenu = () => {
    clearTimeout(userInfoTimeoutId);
    setShowUserInfo((prev) => {
      // If previous state was NOT showing user info, start a close timer.
      if (!prev) {
        let id = setTimeout(() => {
          setShowUserInfo(false);
          setUserInfoTimeoutId(null);
        }, 4000);
        setUserInfoTimeoutId(id);
      }
      return !prev;
    });
  };

  // Send properties to Intercom & Appcues.
  useEffect(() => {
    window.Appcues?.identify(rule5Env + "-" + user.email, {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      role: user.role,
      planType: user.userSubscription?.planType,
    });
  }, [user]);

  // TODO landing page should probably be driven off of plan_type, tho allowed_actions
  // is safer in a way
  useEffect(() => {
    landingPage.current =
      getLandingFromAllowedActions(user.userSubscription?.allowedActions) ||
      "monitor";
    if (user && location.pathname === "/main") {
      history.push("/main/" + landingPage.current);
    }
  }, [user, location.pathname, history]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {displayContext.displayMode !== "iframe" && (
        <>
          <BrowserView>
            <Drawer
              sx={{ position: "absolute" }}
              variant="permanent"
              open={open}
              transitionDuration={{ appear: 0, enter: 50, exit: 50 }}
              classes={{ root: classes.drawer }}
              onMouseEnter={() => {
                setOpen(true);
                menuContext.setOpen(true);
              }}
              onMouseLeave={() => {
                setOpen(false);
                menuContext.setOpen(false);
              }}
            >
              <DrawerHeader>
                <div className={classes.header}>
                  <CSSTransition
                    in={open}
                    unmountOnExit
                    timeout={{ enter: 500, exit: 100 }}
                    classNames="navHeader"
                  >
                    <Stack
                      alignItems="center"
                      className="navHeader"
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        height: "60px",
                      }}
                    >
                      <div className={classes.avatarContainer}>
                        {/* <Avatar src={avatar} className={classes.avatar} onClick={openAvatarMenu}>{user.firstName.toUpperCase().charAt(0)}</Avatar> */}
                        <LoadedAvatar
                          source={
                            user.userSettingInfo
                              ? user.userSettingInfo.profilePicture
                              : null
                          }
                          className={classes.avatar}
                          onClick={openAvatarMenu}
                        >
                          {user.firstName.toUpperCase().charAt(0)}
                        </LoadedAvatar>
                      </div>
                      <div style={{ flexGrow: "1", marginBottom: "10px" }}>
                        <Fade
                          in={!showUserInfo}
                          timeout={{ appear: 400, enter: 800, exit: 200 }}
                        >
                          {user.userSubscription?.planType ===
                          "Content User" ? (
                            <Stack sx={{ ml: -3 }}>
                              <img
                                className={classes.s5LogoLarge}
                                src={require("./res/s5_logo.svg").default}
                                alt="Rule5 Logo Large"
                              />
                              <Typography
                                sx={{ fontSize: "10px", opacity: 0.5, ml: 7 }}
                                textAlign="center"
                              >
                                powered by rule5
                              </Typography>
                            </Stack>
                          ) : (
                            <img
                              className={classes.logoLarge}
                              src={require("./res/r5_logo.svg").default}
                              alt="Rule5 Logo Large"
                            />
                          )}
                        </Fade>
                        <Collapse in={showUserInfo} timeout={400}>
                          <Box
                            sx={{
                              ml: 3,
                              mb: 5,
                              mt: 2,
                              fontSize: "12px",
                              width: "100px",
                            }}
                          >
                            <span
                              style={{
                                opacity: 0.7,
                                display: "block",
                                marginBottom: "4px",
                              }}
                            >
                              {user.email}
                            </span>
                            <Link
                              style={{ marginRight: "4px" }}
                              className={classes.userLink}
                              to="#"
                              onClick={handleLogout}
                            >
                              Log Out
                            </Link>
                            |
                            <Link
                              style={{ marginLeft: "4px" }}
                              className={classes.userLink}
                              to="/main/settings/profile"
                            >
                              My Profile
                            </Link>
                          </Box>
                        </Collapse>
                      </div>
                      <IconButton
                        onClick={toggleDrawer}
                        sx={{ width: "32px", height: "32px", margin: "auto" }}
                      >
                        <LastPage sx={{ transform: "rotate(180deg)" }} />
                      </IconButton>
                    </Stack>
                  </CSSTransition>
                  <CSSTransition
                    in={!open}
                    unmountOnExit
                    timeout={{ appear: 0, enter: 100, exit: 100 }}
                    classNames="logoSmall"
                  >
                    <div
                      className="logoSmall"
                      style={{ flex: "1", height: "60px" }}
                    >
                      <Stack
                        alignItems="center"
                        justifyContent="center"
                        className={classes.logoLink}
                        onClick={toggleDrawer}
                      >
                        <img
                          className={classes.logoSmall}
                          src={require("./res/r5_compact.svg").default}
                          alt="Rule5 Logo Small"
                        />
                      </Stack>
                    </div>
                  </CSSTransition>
                </div>
              </DrawerHeader>
              <Divider sx={{ opacity: 0.5 }} />
              <Stack
                sx={{
                  height: "100%",
                  overflowY: "auto",
                  scrollbarWidth: "none",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  msOverflowStyle: "none",
                }}
              >
                <MenuList expanded={open} />
                <MenuSubList expanded={open} />
              </Stack>
            </Drawer>
          </BrowserView>
        </>
      )}
      <GptConversationProvider>
        <Box
          component="main"
          sx={{
            marginLeft: isBrowser ? "76px" : "0px",
            flexGrow: 1,
            padding: 0,
            height: "100vh",
            backgroundColor: "#FAFAFA",
            // min-width needed for chart.js.
            // See: https://github.com/chartjs/Chart.js/issues/4156#issuecomment-345770012
            minWidth: "375px", // For smaller phones
          }}
        >
          {/* <TransitionGroup>
              <CSSTransition
                key={location.pathname.split("/")[2]}
                classNames="fade"
                // timeout={300}
              > */}
          {/* TODO: display similar to mobilemessage if route is not in allowedactions!  */}
          <Switch className="fade" location={location}>
            <Route exact path="/main">
              <Redirect to={`/main/${landingPage.current}`} />
            </Route>
            <Route
              path="/main/home"
              component={isBrowser ? WSHome : MobileMessage}
            />
            <Route
              path="/main/search"
              component={isBrowser ? WSSearch : MobileMessage}
            />
            <Route
              path="/main/opportunities"
              component={isBrowser ? WSOpportunities : MobileMessage}
            />
            <Route
              path="/main/tasks"
              component={isBrowser ? WSTasks : MobileMessage}
            />
            <Route
              path="/main/connections"
              component={isBrowser ? WSConnections : MobileMessage}
            />
            <Route path="/main/chat/:conversationId?" component={WSChat} />
            <Route path="/main/promptbooks/" component={WSPromptBooks} />
            <Route
              path="/main/content/:contentIdParam?"
              component={WSContent}
            />
            <Route
              path="/main/settings"
              render={() => (
                <WSSettings
                  switchTheme={props.switchTheme}
                  themeIndex={props.themeIndex}
                />
              )}
            />
            <Route path="/main/users" component={ManageUsers} />
            <Route path="/main/accounts" component={ManageSfdcAccounts} />
            <Route exact path="/main/monitor">
              <Redirect to="/main/monitor/accounts" />
            </Route>
            <Route path="/main/monitor/accounts" component={MonitorAccounts} />
            <Route
              path="/main/monitor/account-events"
              component={AccountEvents}
            />
            <Route path="/main/monitor/people" component={MonitorPeople} />
            <Route
              path="/main/monitor/job-change-leads"
              component={JobChangeLeads}
            />
            <Route
              path="/main/personalized-campaign"
              component={MassOutreach}
            />
          </Switch>
          {/* </CSSTransition>
            </TransitionGroup> */}
        </Box>
      </GptConversationProvider>
    </Box>
  );
});

function MobileMessage(props) {
  return (
    <Stack alignItems="center" justifyContent="center" sx={{ height: "100vh" }}>
      <Card
        variant="outlined"
        sx={{ p: 4, maxWidth: "400px", borderRadius: "8px", m: 4 }}
      >
        <img
          style={{
            width: "100px",
            margin: "auto",
            display: "block",
            marginTop: "14px",
          }}
          src={require("./res/r5_logo.svg").default}
          alt="Rule5 Logo Large"
        />
        <br />
        Hello,
        <br />
        <br />
        This page is not supported on mobile devices. Please continue to{" "}
        <a href="/main/chat/">chat</a> or use a desktop or laptop device.
        <br />
        <br />
        Thanks,
        <br />
        rule5 Team
      </Card>
    </Stack>
  );
}

export default MainPage;
