import axios from "axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { rule5properties } from "../properties";

export const useSlackConfig = () => {
  return useQuery(["slackConfig"], fetchSlackConfig, {
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
};

const fetchSlackConfig = async () => {
  try {
    const response = await axios.get(
      rule5properties.userMessageChannels + "?system=Slack"
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("Error fetching Slack configuration:", error);
    throw error;
  }
};

export const useOutreachConnection = () => {
  return useQuery(["outreachConnection"], fetchOutreachConnection, {
    staleTime: 60 * 60 * 1000, // 60 minutes
    retry: 3,
  });
};

const fetchOutreachConnection = async () => {
  try {
    const response = await axios.get(rule5properties.outreachConnections);
    if (response.status === 200) {
      return response.data;
    }
    throw new Error("Failed to fetch Outreach configuration");
  } catch (error) {
    console.error("Error fetching Outreach configuration:", error);
    throw error;
  }
};

export const useOutreachMappings = () => {
  return useQuery(["outreachMappings"], fetchOutreachMappings, {
    staleTime: 5 * 60 * 1000, // 5 minutes
    retry: 3,
  });
};

const fetchOutreachMappings = async () => {
  try {
    const response = await axios.get(rule5properties.orgPreferences);
    if (response.status === 200) {
      return response.data.outreachMappings;
    }
    throw new Error("Failed to fetch Outreach mappings");
  } catch (error) {
    console.error("Error fetching Outreach mappings:", error);
    throw error;
  }
};

export const useOutreachLabels = () => {
  return useQuery(["outreachLabels"], fetchOutreachLabels, {
    staleTime: 5 * 60 * 1000, // 5 minutes
    retry: 3,
  });
};

const fetchOutreachLabels = async () => {
  try {
    const response = await axios.get(
      rule5properties.integrationsLabels + "?salesPlatform=Outreach"
    );
    if (response.status === 200) {
      return response.data;
    }
    throw new Error("Failed to fetch Outreach labels");
  } catch (error) {
    console.error("Error fetching Outreach labels:", error);
    throw error;
  }
};

export const useUpdateOutreachMappings = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (updatedMappings) => updateOutreachMappings(updatedMappings),
    {
      onSuccess: (data) => {
        // Update the cache with the returned data
        queryClient.setQueryData(["outreachMappings"], data.outreachMappings);
      },
    }
  );
};

const updateOutreachMappings = async (updatedMappings) => {
  try {
    const response = await axios.put(rule5properties.orgPreferences, {
      outreachMappings: updatedMappings,
    });
    if (response.status === 200) {
      return response.data;
    }
    throw new Error("Failed to update Outreach mappings");
  } catch (error) {
    console.error("Error updating Outreach mappings:", error);
    throw error;
  }
};

export const useIntegrationTags = () => {
  return useQuery(["integrationTags"], fetchIntegrationTags, {
    staleTime: 60 * 60 * 1000, // 60 minutes
    retry: 3,
  });
};

const fetchIntegrationTags = async () => {
  try {
    const response = await axios.get(
      rule5properties.integrationsTags + "?salesPlatform=Outreach"
    );
    if (response.status === 200) {
      return response.data?.tags;
    }
    throw new Error("Failed to fetch integration tags");
  } catch (error) {
    console.error("Error fetching integration tags:", error);
    throw error;
  }
};
