import { IconButton, Stack, Button } from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  gridExpandedSortedRowIdsSelector,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import { StyledTextfield } from "../../../common/StyledComponents";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

const getFilteredRows = ({ apiRef }) =>
  gridExpandedSortedRowIdsSelector(apiRef);

export default function AgentResultsToolbar(props) {
  const { searchValue, setSearchValue, displayButtons } = props;
  const apiRef = useGridApiContext();

  const handleExport = (options) => apiRef.current.exportDataAsCsv(options);

  const buttonBaseProps = {
    color: "primary",
    size: "small",
    startIcon: <SaveAltIcon />,
  };

  return (
    <Stack direction="row" justifyContent="space-between">
      <StyledTextfield
        // autoFocus
        placeholder="Search results"
        sx={{ width: "360px", py: 0.75 }}
        endAdornment={
          searchValue?.trim().length > 0 ? (
            <IconButton
              size="small"
              sx={{ mr: -0.5 }}
              onClick={() => {
                setSearchValue("");
              }}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ) : (
            <IconButton size="small" sx={{ mr: -0.5 }}>
              <SearchIcon fontSize="small" />
            </IconButton>
          )
        }
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
      />

      {displayButtons && (
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <Button
            {...buttonBaseProps}
            onClick={() =>
              handleExport({
                getRowsToExport: getFilteredRows,
              })
            }
          >
            Export
          </Button>
        </GridToolbarContainer>
      )}
    </Stack>
  );
}
