import React from "react";
import axios from "axios";
import { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Researchcard from "./Researchcard";
import Typography from "@mui/material/Typography";
import { rule5properties } from "../../../../properties";
import CircularProgress from "@mui/material/CircularProgress";
import CustomSnackbar from "../../../../common/CustomSnackbar";
import { convertToEditorState } from "./SingleRteCard";
import RichTextReader from "../../../../common/RichTextReader";
import { isEmpty } from "lodash";
import { Box, Button, Stack } from "@mui/material";
import AccountAgentResults from "../../../monitor/AccountAgentResults";
import { ACCOUNT_EVENTS_COLUMNS } from "../../../monitor/common/monitorColumns";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
  },
  flexContent: {
    textAlign: "left",
    flexGrow: 1,
    position: "relative",
    margin: "10px",
    width: 320,
    "&:hover button": {
      display: "inherit",
    },
  },
}));

export default function Signals(props) {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const previewData = props.previewData;
  const [signalsData, setSignalsData] = React.useState(null);

  useEffect(() => {
    if (previewData) {
      setSignalsData(previewData);
    } else {
      // Call API on initial load
      let parameter = "/" + props.cardId;
      axios
        .get(rule5properties.getCard + parameter)
        .then((response) => {
          let rawdata = response.data;
          if (true) {
            // Validate content
            setSignalsData(rawdata);
          } else {
            // API call failed
            setSnackbarMessage(
              "There was a problem with the " + props.cardType + " Card"
            );
            setOpenSnackbar(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.status);
            console.log(error.response.data);
            setSnackbarMessage(
              "There was a problem with the " + props.cardType + " Card"
            );
            setOpenSnackbar(true);
          }
        });
    }
  }, [previewData]);

  if (!signalsData) {
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={props.cardType}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <div style={{ padding: 30 }}>
          {snackbarMessage === "" && <CircularProgress size={50} />}
          {snackbarMessage !== "" && snackbarMessage}
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
  }

  return (
    <Researchcard
      dragHandleProps={{ ...props.dragHandleProps }}
      toggleExpanded={props.toggleExpanded}
      expanded={props.expanded}
      expandHandler={true}
      opsId={props.opportunity.id}
      cardId={props.cardId}
      title={signalsData.Title}
      closeCard={props.closeCard}
      viewed={props.viewed}
    >
      <Stack direction="row" justifyContent={"end"}>
        <Button
          // variant=""
          href="/main/monitor/accounts"
          target="_blank"
          endIcon={<OpenInNewIcon />}
        >
          Company agents
        </Button>
      </Stack>
      <AccountAgentResults
        staticRows={signalsData.Signals}
        columns={ACCOUNT_EVENTS_COLUMNS.filter(
          (column) => column.field !== "companyName"
        )}
      />
      <CustomSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        snackbarMessage={snackbarMessage}
      />
    </Researchcard>
  );
}
